const customMessages = {
  program: {
    params: {
      knee: "Genou opéré",
      knee__left: "Gauche",
      knee__right: "Droit",
      flexion: "Flexion (°)",
      extension: "Extension (°)",
      effusion: "Effusion (°)",
      flessum: "Niveau de flessum",
      walkingTestDuration: "Durée de marche (s)",
    },
    questionnaires: {
      walkingTest: "Test de marche",
      kneeRom: "Mobilité du genou",
    }
  },
};

export default customMessages;
