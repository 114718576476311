import { Dictionary, isNil, omitBy, toPairs, transform, uniqBy, xor } from 'lodash';
import { useTranslate } from 'ra-core';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormContainer, RadioButtonGroup, TextFieldElement } from 'react-hook-form-mui';

import {
  Alert, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Paper,
  Radio, RadioGroup, TextField, Typography
} from '@mui/material';

import {
  deleteAsyncData, getAsyncFetchData, postAsyncData, useFetchData
} from '../utils/useAdminApi';

export type UserProgramParamsPostFormat = Dictionary<ProgramAnswers>
export type ProgramAnswers = Dictionary<string | number | null>

export type Question = {
  id: string
  type: string
  enum?: Array<string>
  enumMultiple?: Array<string>
}

export type Questionnaire = {
  id: string
  questions: Array<Question>
  answers?: Array<Question>
}

export const UserProgramParams = (props) => {
  // Load translation
  const translate = useTranslate();

  // Get data from API
  const { data: dataProgram, loading: loadingProgram, error: errorProgram } = useFetchData("/adminApi/program/" + props.user.program);
  const { data: userAnswers, loading, error } = useFetchData("/adminApi/user/" + props.user.id + "/programParams") as { data: Array<any>, loading: any, error: any };

  // States definition
  const [showDetails, setShowDetails] = useState(false);
  const [status, setStatus] = useState<string>("");

  const formAnswers = {} as UserProgramParamsPostFormat;

  // Declare questionnaires
  const questionnaires = dataProgram !== undefined ? dataProgram.questionnaires : undefined as Array<Questionnaire> | undefined

  // Initialise form values from questionnaires
  if (questionnaires)
    for (let questionnaire of questionnaires as Array<Questionnaire>) {
      for (let question of questionnaire.questions) {
        formAnswers[questionnaire.id] = { [question.id]: null }
      }
    }

  // Set the default form answers if defined in user's data
  if (userAnswers != null) {
    // Sort answers by date from oldest to latest
    const answersByDate = userAnswers.sort((a, b) => (a.updatedAt as string).localeCompare(b.updatedAt as string));

    for (let answer of answersByDate) {
      toPairs(answer.values).map(([answerName, answerValue]) => {
        if (formAnswers[answer.questionnaireId] === undefined) formAnswers[answer.questionnaireId] = {}
        formAnswers[answer.questionnaireId][answerName] = answerValue as string | number;
      })
    }
  }

  // Form controller
  const { control, handleSubmit, register, reset } = useForm({ defaultValues: formAnswers });

  // Hash the default answers values for the useEffect
  const formHashSignature = JSON.stringify(formAnswers);
  // Reset the form's default values if the hash changes
  useEffect(() => {
    reset(formAnswers);
  }, [formHashSignature]);

  // Loading & undefined variables handling
  if (loading || loadingProgram) {
    return <CircularProgress size={25} thickness={2} />;
  }
  if (!userAnswers || !dataProgram || errorProgram || error) {
    return <CircularProgress size={25} thickness={2} />;
  }
  if (questionnaires === undefined) return <Alert severity='warning'>{translate('resources.users.programParams.noQuestionnaire')}</Alert>

  // Function called to submitting the form's values to the API
  const createNewAnswer = async (answers: UserProgramParamsPostFormat) => {
    setStatus('loading');

    // Remove undefined or nullish properties from answers
    const answersNil = {
      ...transform(
        toPairs(answers),
        (questionnaires, [name, value]) => (
          questionnaires[name] = omitBy(value, isNil)
        ),
        {}
      )
    }

    // Prepare sent data
    const sentData = {
      answers: answersNil,
      userId: props.user.id,
      programId: props.user.program
    };

    // Send POST request
    const { data, error } = await postAsyncData("/adminApi/user/" + props.user.id + "/programParams", sentData);
    if (!error)
      setStatus('success');
    else
      setStatus('error');
  }

  // Form fields onChange handler
  function handleOnChange(questionnaireId: string, questionId: string, value: string | number | null) {
    formAnswers[questionnaireId][questionId] = value;
  }

  // Form submit handler
  function submitFunction(finalAnswers: UserProgramParamsPostFormat) {
    createNewAnswer(finalAnswers);
  }

  return (
    <>
      {!showDetails ?
        <>
          {userAnswers == null && <Typography>{translate('resources.users.programParams.noAnswerFound')}</Typography>}
          <Button variant="contained" onClick={() => setShowDetails(true)}>
            {translate('resources.users.programParams.newAnswer')}
          </Button>
        </>
        :
        <form onSubmit={handleSubmit((data) => submitFunction(data))} noValidate style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {/* Group by questionnaires */}
          {questionnaires.map((questionnaire, questionnaireId) => {
            return (
              <Paper key={questionnaireId} style={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Typography variant='h5'>{translate(`program.questionnaires.${questionnaire.id}`, { '_': questionnaire.id })}</Typography>
                {/* Map the questionnaire's questions */}
                {questionnaire.questions.map((question, questionId) => {
                  // Register the field name for the current question
                  const registeredQuestion = register(`${questionnaire.id}.${question.id}`);
                  return (
                    <div key={questionId}>
                      {/* <Typography variant='body1'>{translate(`program.params.${question.id}`)}</Typography> */}

                      {/* If question is a number-type enum */}
                      {question.enum && question.type === 'number' && (
                        <RadioButtonGroup
                          control={control}
                          type={'number'}
                          label={translate(`program.params.${question.id}`, { '_': question.id })}
                          options={question.enum.map(enumValue => ({ id: parseInt(enumValue), label: enumValue }))}
                          {...registeredQuestion} // Property "name" declared here, "name" should be equal to `${questionnaire.id}.${question.id}`
                          onChange={(e: string | undefined) => {
                            handleOnChange(questionnaire.id, question.id, typeof e === 'string' ? parseInt(e) : null);
                          }}
                          row
                        />
                      )}

                      {/* If question is a text-type enum */}
                      {question.enum && question.type === 'string' && (
                        <RadioButtonGroup
                          control={control}
                          type={'string'}
                          label={translate(`program.params.${question.id}`)}
                          options={question.enum.map(enumValue => ({ id: enumValue, label: translate(`program.params.${question.id}__${enumValue}`, { '_': enumValue }) }))}
                          {...registeredQuestion} // Property "name" declared here, "name" should be equal to `${questionnaire.id}.${question.id}`
                          onChange={(e: string | undefined) => {
                            handleOnChange(questionnaire.id, question.id, typeof e === 'string' ? e : null);
                          }}
                          row
                        />
                      )}

                      {/* If question is a number field */}
                      {!question.enum && question.type === 'number' && (
                        <TextFieldElement
                          control={control}
                          margin='dense'
                          type='number'
                          label={translate(`program.params.${question.id}`, { '_': question.id })}
                          {...registeredQuestion} // Property "name" declared here, "name" should be equal to `${questionnaire.id}.${question.id}`
                          onChange={(e) => {
                            handleOnChange(questionnaire.id, question.id, parseInt(e.target.value));
                          }}
                        />
                      )}

                      {/* If question is a text field */}
                      {!question.enum && question.type === 'string' && (
                        <TextFieldElement
                          control={control}
                          margin='dense'
                          type='text'
                          label={translate(`program.params.${question.id}`)}
                          {...registeredQuestion} // Property "name" declared here, "name" should be equal to `${questionnaire.id}.${question.id}`
                          onChange={(e) => {
                            handleOnChange(questionnaire.id, question.id, e.target.value);
                          }}
                        />
                      )}
                    </div>
                  )
                }
                )}
              </Paper>
            )
          })}

          {/* Submit status */}
          {status === 'success' &&
            <Alert variant='standard' severity='success'>
              {translate('resources.users.programParams.formSuccess')}
            </Alert>}
          {status === 'error' &&
            <Alert variant='standard' severity='error'>
              {translate('resources.users.programParams.formError')}
            </Alert>}

          {/* Submit button */}
          <Button type={'submit'} color={'primary'} variant='contained' disabled={status === 'loading'}>
            {translate('resources.misc.validate')}
          </Button>
        </form>
      }
    </>
  )
}

export default UserProgramParams;