const customMessages = {
  program: {
    params: {
      pasValue: "Score",
      walkingAssistance: "Pouvez-vous marcher sans aide ?",
      walkingAssistance__YES: "Oui",
      walkingAssistance__NO: "Non",
      respiratorySymptomsFrequency: "À quelle fréquence ressentez-vous des difficultés respiratoires lors d'une activité physique légère ?",
      respiratorySymptomsFrequency__NEVER: "Jamais",
      respiratorySymptomsFrequency__SOMETIMES: "Parfois",
      respiratorySymptomsFrequency__OFTEN: "Souvent",
      maxDurationWithoutBreathingDifficulty: "Quelle est la plus longue durée pendant laquelle vous pouvez vous engager dans une activité physique sans difficultés respiratoires significatives ?",
      maxDurationWithoutBreathingDifficulty__LESS_THAN_FIVE_MINUTES: "Moins de 5 minutes",
      maxDurationWithoutBreathingDifficulty__FIVE_TO_TEN_MINUTES: "5 à 10 minutes",
      maxDurationWithoutBreathingDifficulty__MORE_THAN_TEN_MINUTES: "Plus de 10 minutes",
      effortIntensityTolerance: "Quel niveau d'intensité d'effort pouvez-vous tolérer sans exacerbation de vos symptômes respiratoires ?",
      effortIntensityTolerance__VERY_LIGHT: "Efforts très légers (ex. marche lente)",
      effortIntensityTolerance__LIGHT: "Efforts légers (ex. marche rapide)",
      effortIntensityTolerance__MODERATE_OR_INTENSE: "Efforts modérés ou intenses (ex. jogging lent, course rapide)",
      recoveryTimePostEffort: "Combien de temps vous faut-il pour récupérer après un effort physique, avant de vous sentir stable au niveau de la respiration ?",
      recoveryTimePostEffort__LESS_THAN_TEN_MINUTES: "Moins de 10 minutes",
      recoveryTimePostEffort__MORE_THAN_TEN_MINUTES: "Plus de 10 minutes",
      alixPhysicalActivityGoals: "Objectifs en activité physique",
      alixPhysicalActivityGoals__WALK_SIX_MINUTES_UNINTERRUPTED: "Marcher sans s’arrêter pendant 6 minutes.",
      alixPhysicalActivityGoals__CLIMB_ONE_FLIGHT_STAIRS_UNINTERRUPTED: "Monter un étage d’escaliers sans s’arrêter.",
      alixPhysicalActivityGoals__PERFORM_GENTLE_STRENGTH_TRAINING: "Réaliser une séance de renforcement musculaire douce 2 à 3 fois par semaine.",
      alixPhysicalActivityGoals__DAILY_MOBILITY_AND_STRETCHING: "Pratiquer un exercice de mobilité et d’étirement tous les jours.",
      alixPhysicalActivityGoals__TEN_DAILY_RESPIRATORY_EXERCISES: "Faire 10 répétitions d’exercices respiratoires chaque jour.",
      alixDailyAutonomyGoals: "Objectifs en autonomie quotidienne",
      alixDailyAutonomyGoals__DRESS_WASH_WITHOUT_BREATHLESSNESS: "S’habiller et se laver sans essoufflement excessif.",
      alixDailyAutonomyGoals__CARRY_LIGHT_GROCERY_BAG: "Pouvoir porter un sac de courses léger sans s’arrêter.",
      alixDailyAutonomyGoals__PERFORM_GENTLE_STRENGTH_TRAINING: "Réaliser une séance de renforcement musculaire douce 2 à 3 fois par semaine.",
      alixDailyAutonomyGoals__PERFORM_SIMPLE_HOUSEHOLD_TASKS: "Faire les tâches ménagères simples (cuisine, vaisselle, rangement) sans trop d’effort.",
      alixDailyAutonomyGoals__SIT_STAND_MULTIPLE_TIMES: "Se lever et s’asseoir plusieurs fois sans difficulté.",
    },
    questionnaires: {
      pas: "Niveau d'activité physique (Questionnaire PAS)",
      ssrRespiQuestionnaire: "Questions spécifiques",
    }
  }
};

export default customMessages;
