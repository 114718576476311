// Enum for ProgramStatus
export enum ProgramStatus {
  Active = "active",
  Invalid = "invalid",
  Outdated = "outdated",
}

// Enum for ProgramVisibility
export enum ProgramVisibility {
  Hidden = "HIDDEN",
  Public = "PUBLIC",
}

// Interface for RecommendationsParamsType
export interface RecommendationsParamsType {
  api: string;
  apiId: string;
  promptTemplate: string;
}

// Interface for SessionParamsType
export interface SessionParamsType {
  model: string;
  programRules: string;
  promptTemplate: string;
}

// Interface for AiParamsType
export interface AiParamsType {
  recommendations: RecommendationsParamsType;
  session: SessionParamsType;
}

// Interface for Questions
export interface Question {
  id: string;
  type?: string;
  enum?: string[];
  enumMultiple?: string[];
}

// Interface for QuestionnairesData
export interface QuestionnairesData {
  id: string;
  questions: Question[];
}

// Interface for ProgramOptionsType
export interface ProgramOptionsType {
  indoorSessions: Boolean
  walkSessions: Boolean
}

// Interface for AlixMedProgram
export interface ProgramModel {
  id: string;
  displayName: string;
  description?: string;
  icon?: string;
  lastInsertPatientId?: number;
  status: ProgramStatus;
  questionnaires?: QuestionnairesData[];
  options?: ProgramOptionsType; // Assuming `ProgramOptionsType` is defined elsewhere
  generateSessionMode: string;
  aiParams: AiParamsType;
  visibility: ProgramVisibility;
}
