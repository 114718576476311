const customMessages = {
  program: {
    params: {
      pasValue: "Score",
      knee: "De quel genou souffrez-vous ?",
      knee__left: "Gauche",
      knee__right: "Droit",
      walkingTestDuration: "Durée de marche (s)",
    },
    questionnaires: {
      pas: "Questionnaire PAS",
      kneeRom: "Question spécifique",
      walkingTest: "Test de marche de 4m",
    }
  }
};

export default customMessages;
