import { useTranslate } from 'ra-core';
import { useEffect, useState } from 'react';
import {
  Datagrid, EditButton, FunctionField, List, ListContextProvider, ListControllerResult,
  Pagination, ShowButton, SortPayload, TextField, TextInput, Title, useGetManyReference,
  useGetOne, useRedirect
} from 'react-admin';
import { AmplifyFilter } from 'react-admin-amplify';
import { useParams } from 'react-router-dom';
import useRouteExists from '../utils/useRouteExists';

import { Button, CircularProgress } from '@mui/material';
import config from '../config';
import { getAsyncFetchData } from '../utils/useAdminApi';
import { ProgramModel } from '../program/model';
import { InviteCodeModel } from './model';
import inviteCodes from '.';
import FilteredList from '../components/FilteredList';

const InviteCodesByProgramId = (props) => {

  const { programId } = useParams();
  const redirect = useRedirect();
  const translate = useTranslate();
  const routeExists = useRouteExists();
  const [loadingInviteCodes, setLoadingInviteCodes] = useState(false);
  const [loadingProgram, setLoadingProgram] = useState(false);
  const [error, setError] = useState(false);
  const [codesData, setCodesData] = useState<InviteCodeModel | null>(null);
  const [programData, setProgramData] = useState<ProgramModel | null>(null);
  useEffect(() => {
    fetchProgramData()
  }, [programId])

  const fetchProgramData = async () => {
    setLoadingProgram(true);
    try {
      const response = await getAsyncFetchData(`/adminApi/program/${programId}`);
      if (response.error) {
        console.error('Failed to fetch program');
        setError(true);
      }
      const data = response.data;
      setProgramData(data);

    } catch (error) {
      console.error("Error on fetching data:", error);
    } finally {
      setLoadingProgram(false);
    }
  };

  useEffect(() => {
    fetchInviteCodesData()
  }, [programId])

  const fetchInviteCodesData = async () => {
    setLoadingInviteCodes(true);
    try {
      const response = await getAsyncFetchData(`/adminApi/program/${programId}/invitecodes`);
      if (response.error) {
        console.error('Failed to fetch invite codes');
        setError(true);
      }
      const data = response.data;
      setCodesData(data);

    } catch (error) {
      console.error("Error on fetching data:", error);
    } finally {
      setLoadingInviteCodes(false);
    }
  };

  if (loadingInviteCodes || loadingProgram || !codesData || !programData) { return <CircularProgress size={25} thickness={2} />; }
  if (error) { return <p>ERROR</p>; }

  const attributesToDisplay = [
    {
      key: "name",
      type: "String",
      filter: "stringStart",
      name: translate("resources.codes.name")
    },
    {
      key: "uses",
      type: "String",
      name: translate("resources.codes.uses")
    },
    {
      key: "createdAt",
      type: "moment",
      name: translate("resources.codes.createdAt")
    },
  ]

  const InviteCodesTitle = () => {
    return (
      <Title title={translate(`pos.codeListTitle`, { programName: programData?.displayName })} />
    );
  };


  return inviteCodes ? (
    <>
      <InviteCodesTitle />
      {routeExists(`/${programId}/invite-codes/create`) ?
        <div style={{ display: 'flow-root', width: '100%' }}>
          <Button sx={{ float: 'right', mt: 2, mr: 1 }} onClick={() => redirect(`/${programId}/invite-codes/create`)} >{translate('resources.codes.create.title')}</Button>
        </div>
        : null
      }

      <FilteredList
        data={codesData}
        displayAttributes={attributesToDisplay}
        path={`/${programId}/invite-codes`}
      />
    </>
  ) : null;
};

export const CodeProgramList = () => {
  return (
    <InviteCodesByProgramId />
  );
};

export default CodeProgramList;