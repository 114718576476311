const customMessages = {
  program: {
    params: {
      postPartumTiming: "Quand avez-vous accouché ?",
      postPartumTiming__10_WEEKS_OR_LESS: "10 semaines ou moins",
      postPartumTiming__BETWEEN_11_WEEKS_AND_5_MONTHS: "Entre 11 semaines et 5 mois",
      postPartumTiming__MORE_THAN_5_MONTHS: "Plus de 5 mois",
      postPartumFollowUpB: "Avez-vous complété le Programme 1 ou consulté un(e) physiothérapeute en rééducation périnéale ?",
      postPartumFollowUpB__YES: "Oui",
      postPartumFollowUpB__NO: "Non",
      postPartumFollowUpC: "Quelles actions avez-vous entreprises concernant votre rééducation ou reprise d'activité physique ?",
      postPartumFollowUpC__PP_C1: "J'ai complété les Programmes 1 et/ou 2, repris une activité physique sans symptômes, ou réalisé des exercices de base en rééducation",
      postPartumFollowUpC__PP_C2: "Je n'ai pas vraiment repris d'activité physique ou entamé un programme de rééducation"
    },
    questionnaires: {
      ppQuestionnaire: "Questionnaire post-partum",
    }
  }
};

export default customMessages;
