import { useMemo } from 'react';
import { match } from 'path-to-regexp';
import { useCustomRoutes } from '../App';

/**
 * Custom hook to check if a route exists in the application.
 * Supports dynamic routes with parameters.
 * Uses useMemo to cache route regex patterns.
 * @returns {Function} checkRouteExists - Function to verify if a route exists.
 */
const useRouteExists = () => {
  const customRoutes = useCustomRoutes();

  const routeMatchers = useMemo(() => {
    return customRoutes.map((route) => ({
        path: route.path,
        matcher: match(route.path, { decode: decodeURIComponent }), // Creates a matcher for the route
    }));
}, [customRoutes]);

  /**
   * Checks if a route exists based on its path.
   * Supports dynamic paths like "/post/123".
   * @param {string} path - The path of the route to check (e.g., "/post/123").
   * @returns {boolean} - True if the route exists, false otherwise.
   */
  const checkRouteExists = (path: string): boolean => {
    return routeMatchers.some(({ matcher }) => !!matcher(path));
};

  return checkRouteExists;
};

export default useRouteExists;