import { API } from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import { useEffect, useState } from "react";
import config from "../config";

const environment = String(process.env.REACT_APP_ID);
const currentConfig = config[environment];

export const useFetchData: any = (path) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(undefined);
  useEffect(() => {
    const callApi = async () => {
      try {
        setLoading(true);
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();

        const response = await API.get(currentConfig.apiName, path, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }).catch((e) => {
          setError(e);
          console.log("error useFetchData", e);
        });
        if (!response) {
          setError(true);
        } else {
          setData(response.data);
        }
      } catch (e) {
        setError(true);
        console.error("Error in useFetchData:", e);
      } finally {
        setLoading(false);
      }
    };
    callApi();
  }, [path]);
  return {
    error: error,
    loading: loading,
    data: data,
  };
};

export const getAsyncFetchData = async (path, params = {}) => {
  let error = null;
  let response: any = {};
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  response = await API.get(currentConfig.apiName, path, {
    ...params,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    console.log("error useFetchData", e);
    error = e;
  });
  return {
    data: response?.data,
    error: error,
  };
};

export const postAsyncData = async (path, params = {}) => {
  let error: any = undefined;
  let response: any = undefined;
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  response = await API.post(currentConfig.apiName, path, {
    body: params,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    console.log("error useFetchData", e);
    error = e;
  });
  return {
    data: response,
    error: error,
  };
};

export const deleteAsyncData = async (path, params = {}) => {
  let error: any = undefined;
  let response: any = undefined;
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  response = await API.del(currentConfig.apiName, path, {
    body: params,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    console.log("error useFetchData", e);
    error = e;
  });
  return {
    data: response,
    error: error,
  };
};

export async function callAPI({
  apiName = currentConfig.apiName,
  apiPath,
  type = "get",
  extraParams,
}: {
  apiName?: string;
  apiPath: string;
  type?: "post" | "get" | "delete";
  extraParams?: any;
}) {
  // Initialize return values
  let error = undefined as any;
  let response = null as any;

  // Call API using POST
  if (type === "post") {
    response = await API.post(apiName, apiPath, { body: extraParams }).catch(
      (e) => {
        console.log(`Error in callAPI using POST on ${apiName}:${apiPath}`, e);
        error = e;
      }
    );
  }

  // Call API using GET
  else if (type === "get") {
    response = await API.get(apiName, apiPath, extraParams).catch((e) => {
      console.log(`Error in callAPI using GET on ${apiName}:${apiPath}`, e);
      error = e;
    });
  }

  // Call API using GET
  else if (type === "delete") {
    response = await API.del(apiName, apiPath, { body: extraParams }).catch(
      (e) => {
        console.log(
          `Error in callAPI using DELETE on ${apiName}:${apiPath}`,
          e
        );
        error = e;
      }
    );
  } else {
    error = `Incorrect call type, got ${type}`;
  }

  // Return api result
  return {
    response,
    error,
  };
}
