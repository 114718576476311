const customMessages = {
  program: {
    params: {
      knee: "À quel genou allez-vous être opéré ?",
      knee__left: "Gauche",
      knee__right: "Droit",
      phase: "Phase",
      phase__raacc_0: "Pré-opératoire",
      phase__raacc_1: "Post-opératoire immédiat",
      phase__raacc_2: "Post-opératoire moyen terme",
      phase__raacc_3: "Post-opératoire long terme",
    },
    questionnaires: {
      kneeRom: "Mobilité du genou",
      phase: "Phase",
    }
  }
};

export default customMessages;
