import { AppBar, Logout, UserMenu } from 'react-admin';
import {
    Box,
    Chip,
    Typography,
    useMediaQuery,
    Theme,
    IconButton,
} from '@mui/material';
import { useRedirect } from 'react-admin';
import HomeIcon from '@mui/icons-material/Home';

const CustomUserMenu = () => (
    <UserMenu>
        {/*<ConfigurationMenu />*/}
        <Logout />
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    const awsExports = require('../aws-exports.js')
    const amplifyEnv = awsExports.default?.aws_content_delivery_bucket?.match(/.*-(\w+)/)[1]
    const redirect = useRedirect();

    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );

    const goHome = () => {
        redirect(`/`);
    }
    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={2}
            userMenu={<CustomUserMenu />}
            alwaysOn={true}
        >
            {
                amplifyEnv != "prod" &&
                <Chip label={amplifyEnv} color="error" size="small" sx={{ mr: 1 }} />
            }
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                id="react-admin-title"
            />
            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
            <IconButton color="inherit" onClick={goHome}>
                <HomeIcon />
            </IconButton>
        </AppBar>
    );
};

export default CustomAppBar;